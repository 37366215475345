<template>
    <div id="main-videos-container" class="main-videos-container">
        <div id="title-container" class="title-container">
            <h1 class="title-style">Vídeos</h1>
        </div>
        <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user" id="user-logged-container" class="user-logged-container">
            <div class="div-tab-videos">
                <Tab :tab_options="tab_obj" @_toggle_tab="_getIndexTab" class="tab-videos"/>
            </div>
            <div v-if="tab_indexId=='masterclass'">

                <div class="accordion-video-content">
                    <div v-for="(item, index) in masterclass_nodes" :key="index" class="masterclass-videos-content-container">
                        <div class="title-style-container">{{ item.title }}</div>
                        <iframe width="435" height="245" :src="masterclass_urls[index]" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>

                <!--<div class="read-more-masterclass-btn-container">
                    <button class="read-more-masterclass-btn-style">Ver más</button>
                    <div class="scrollToTop-container">
                        <button @click="_scrollToTop()" class="scrollToTop-style">
                            <span class="material-icons-outlined icon-up">expand_less</span>
                        </button>
                    </div>
                </div>-->
                
            </div>
            <div v-else-if="tab_indexId=='media'">

                <div class="accordion-video-content">
                    <div v-for="(item, index) in media_nodes_vimeo" :key="'A' + index" class="media-video-content-container">
                        <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                        <iframe width="435" height="245" :src="media_urls_vimeo[index]" frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div v-for="(item, index) in media_nodes_youtube" :key="'B' + index" class="media-video-content-container">
                        <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                        <iframe width="435" height="245" :src="media_urls_youtube[index]" frameborder="0" allowfullscreen></iframe>
                    </div>
                    
                </div>
                
                <div id="more-media-videos">
                    <div v-for="(item, index) in media_nodes_vimeo_more" :key="'A' + index" class="media-video-content-container">
                        <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                        <iframe width="435" height="245" :src="media_urls_vimeo_more[index]" frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div v-for="(item, index) in media_nodes_youtube_more" :key="'B' + index" class="media-video-content-container">
                        <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                        <iframe width="435" height="245" :src="media_urls_youtube_more[index]" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>

                <div id="more-more-media-videos">
                    <div v-for="(item, index) in media_nodes_vimeo_more_more" :key="'A' + index" class="media-video-content-container">
                        <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                        <iframe width="435" height="245" :src="media_urls_vimeo_more_more[index]" frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div v-for="(item, index) in media_nodes_youtube_more_more" :key="'B' + index" class="media-video-content-container">
                        <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                        <iframe width="435" height="245" :src="media_urls_youtube_more_more[index]" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
                
                <div class="read-more-media-btn-container">
                    <button @click="_getMediaVideosMore()" class="read-more-media-btn-style">Ver más</button>
                </div>
                

            </div>
            <div v-else-if="tab_indexId=='48segundos'" class="accordion-video-content">

                <div v-for="(item, index) in x48secs_nodes" :key="index" class="x48sec-video-content-container">
                    <div class="title-style-container"><span>{{ item.title }}</span></div>
                    <iframe width="435" height="245" :src="x48secs_urls[index]" frameborder="0" allowfullscreen></iframe>
                </div>

                <div v-for="(item, index) in x48secs_nodes_more" :key="index" class="x48sec-video-content-container">
                    <div class="title-style-container"><span>{{ item.title }}</span></div>
                    <iframe width="435" height="245" :src="x48secs_urls_more[index]" frameborder="0" allowfullscreen></iframe>
                </div>

                <div v-for="(item, index) in x48secs_nodes_more_more" :key="index" class="x48sec-video-content-container">
                    <div class="title-style-container"><span>{{ item.title }}</span></div>
                    <iframe width="435" height="245" :src="x48secs_urls_more_more[index]" frameborder="0" allowfullscreen></iframe>
                </div>

                <div v-for="(item, index) in x48secs_nodes_more_more_more" :key="index" class="x48sec-video-content-container">
                    <div class="title-style-container"><span>{{ item.title }}</span></div>
                    <iframe width="435" height="245" :src="x48secs_urls_more_more_more[index]" frameborder="0" allowfullscreen></iframe>
                </div>

                <div v-for="(item, index) in x48secs_nodes_more_more_more_more" :key="index" class="x48sec-video-content-container">
                    <div class="title-style-container"><span>{{ item.title }}</span></div>
                    <iframe width="435" height="245" :src="x48secs_urls_more_more_more_more[index]" frameborder="0" allowfullscreen></iframe>
                </div>

                <div class="read-more-48segundos-btn-container">
                    <button @click="_get48secsVideosMore()" class="read-more-48segundos-btn-style">Ver más</button>
                </div>
                
            </div>
            <div v-else-if="tab_indexId=='bunkerinversion'" class="accordion-video-content">
                
                <div v-for="(item, index) in investment_bunker_nodes" :key="index" class="investment-bunker-video-content-container">
                    <div class="title-style-container"><span>{{ item.title }}</span></div>
                    <iframe width="435" height="245" :src="investment_urls[index]" frameborder="0" allowfullscreen></iframe>
                </div>

                <!--<div class="read-more-bunkerinversion-btn-container">
                    <button class="read-more-bunkerinversion-btn-style">Ver más</button>
                    <div class="scrollToTop-container">
                        <button @click="_scrollToTop()" class="scrollToTop-style">
                            <span class="material-icons-outlined icon-up">expand_less</span>
                        </button>
                    </div>
                </div>-->

            </div>
        </div>
        <div v-else class="not-logged-view">
            <div>
                <Tab :tab_options="tab_obj" @_toggle_tab="_getIndexTab" />
            </div>
            <div id="not-logged-videos-container">
                <div v-if="tab_indexId=='masterclass'">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_1.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_2.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_3.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_4.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_5.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_1.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_1.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_1.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_1.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_1.jpg" @click.prevent="_infoMessage()">
                    <img class="image-style" src="@/views/masterclass-anonymous-images/blur_1.jpg" @click.prevent="_infoMessage()">
                </div>
                <div v-else-if="tab_indexId=='media'">

                    <div class="accordion-video-content">
                    <div v-for="(item, index) in media_nodes_vimeo" :key="'A' + index" class="media-video-content-container">
                        <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                        <iframe width="435" height="245" :src="media_urls_vimeo[index]" frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div v-for="(item, index) in media_nodes_youtube" :key="'B' + index" class="media-video-content-container">
                        <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                        <iframe width="435" height="245" :src="media_urls_youtube[index]" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div id="more-media-videos">
                        <div v-for="(item, index) in media_nodes_vimeo_more" :key="'A' + index" class="media-video-content-container">
                            <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                            <iframe width="435" height="245" :src="media_urls_vimeo_more[index]" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div v-for="(item, index) in media_nodes_youtube_more" :key="'B' + index" class="media-video-content-container">
                            <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                            <iframe width="435" height="245" :src="media_urls_youtube_more[index]" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div id="more-more-media-videos">
                        <div v-for="(item, index) in media_nodes_vimeo_more_more" :key="'A' + index" class="media-video-content-container">
                            <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                            <iframe width="435" height="245" :src="media_urls_vimeo_more_more[index]" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div v-for="(item, index) in media_nodes_youtube_more_more" :key="'B' + index" class="media-video-content-container">
                            <div class="title-style-container"><span>{{ item.field_short_title.und[0].safe_value }}</span></div>
                            <iframe width="435" height="245" :src="media_urls_youtube_more_more[index]" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>

                    <div class="read-more-media-btn-container">
                        <button @click="_getMediaVideosMore()" class="read-more-media-btn-style">Ver más</button>
                    </div>

                </div>
                <div v-else-if="tab_indexId=='48segundos'">

                    <div v-for="(item, index) in x48secs_nodes" :key="index" class="x48sec-video-content-container">
                        <div class="title-style-container"><span>{{ item.title }}</span></div>
                        <iframe width="435" height="245" :src="x48secs_urls[index]" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div v-for="(item, index) in x48secs_nodes_more" :key="index" class="x48sec-video-content-container">
                        <div class="title-style-container"><span>{{ item.title }}</span></div>
                        <iframe width="435" height="245" :src="x48secs_urls_more[index]" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div v-for="(item, index) in x48secs_nodes_more_more" :key="index" class="x48sec-video-content-container">
                        <div class="title-style-container"><span>{{ item.title }}</span></div>
                        <iframe width="435" height="245" :src="x48secs_urls_more_more[index]" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div v-for="(item, index) in x48secs_nodes_more_more_more" :key="index" class="x48sec-video-content-container">
                        <div class="title-style-container"><span>{{ item.title }}</span></div>
                        <iframe width="435" height="245" :src="x48secs_urls_more_more_more[index]" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div v-for="(item, index) in x48secs_nodes_more_more_more_more" :key="index" class="x48sec-video-content-container">
                        <div class="title-style-container"><span>{{ item.title }}</span></div>
                        <iframe width="435" height="245" :src="x48secs_urls_more_more_more_more[index]" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div class="read-more-media-btn-container">
                        <button @click="_get48secsVideosMore()" class="read-more-media-btn-style">Ver más</button>
                    </div>

                </div>
                <div v-else-if="tab_indexId=='bunkerinversion'">

                    <div v-for="(item, index) in investment_bunker_nodes" :key="index" class="investment-bunker-video-content-container">
                        <div class="title-style-container"><span>{{ item.title }}</span></div>
                        <iframe width="435" height="245" :src="investment_urls[index]" frameborder="0" allowfullscreen></iframe>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    import axios from 'axios';
    import authHeader from "@/services/auth";

    import Tab from "@/components/helpers/Tab.vue";

    import APICaller from "@/components/codeComponents/APICaller.js";

    export default {
    mixins:[APICaller],
    name: 'Videos',
    components: {
        Tab
    },
    data: () => ({
        media_nodes_vimeo: [],
        media_nodes_youtube: [],
        media_urls_vimeo: [],
        media_urls_youtube: [],

        media_nodes_vimeo_more: [],
        media_nodes_youtube_more: [],
        media_urls_vimeo_more: [],
        media_urls_youtube_more: [],

        media_nodes_vimeo_more_more: [],
        media_nodes_youtube_more_more: [],
        media_urls_vimeo_more_more: [],
        media_urls_youtube_more_more: [],

        x48secs_nodes: [],
        x48secs_urls: [],

        x48secs_nodes_more: [],
        x48secs_urls_more: [],

        x48secs_nodes_more_more: [],
        x48secs_urls_more_more: [],

        x48secs_nodes_more_more_more: [],
        x48secs_urls_more_more_more: [],

        x48secs_nodes_more_more_more_more: [],
        x48secs_urls_more_more_more_more: [],

        masterclass_nodes: [],
        masterclass_urls: [],

        investment_bunker_nodes: [],
        investment_urls: [],

        userNotLoggedMediaVideos_vimeo_first_5_videos: [],
        userNotLoggedMediaVideos_vimeo_remaining_videos: [],
        userNotLoggedMediaVideos_youtube: [],
        userNotLoggedMasterClassVideos_first_5_videos: [],
        userNotLoggedMasterClassVideos_remaining_videos: [],
        userNotLogged48secsVideos_first_5_videos: [],
        userNotLogged48secsVideos_remaining_videos: [],
        userNotLoggedInvestmentBunkerVideos: [],

        userNotLoggedURLMedia_vimeo_first_5_videos: [],
        userNotLoggedURLMedia_vimeo_remaining_videos: [],
        userNotLoggedURLMedia_youtube: [],
        userNotLoggedURLMasterClass: [],
        userNotLoggedURLMasterClass_first_5_videos: [],
        userNotLoggedURLMasterClass_remaining_videos: [],
        userNotLoggedURL48secs_first_5_videos: [],
        userNotLoggedURL48secs_remaining_videos: [],
        userNotLoggedURLInvestmentBunker: [],

        media_btn_counter: 0,
        seconds_btn_counter: 0,
        not_logged_media_btn_counter: 0,
        not_logged_seconds_btn_counter: 0,

        tab_indexId: "media",

        tab_obj: [
            {
                tab_name: "Medios",
                tab_id: "media"
            },
            {
                tab_name: "48 segundos",
                tab_id: "48segundos"
            },
            {
                tab_name: "Búnker de inversión",
                tab_id: "bunkerinversion"
            },
            {
                tab_name: "MasterClass",
                tab_id: "masterclass"
            }
        ],

    }),
    mounted() {

    },
    created() {

        this._APICallerManager();
        this._APICallerManager_not_logged();
        
    },
    computed: {
        ...mapGetters(['_g_IsUserLogged']),
        ...mapGetters(['_g_User']),
    },
    methods: {

        _getIndexTab(tab_id) {
            this.tab_indexId = tab_id;
            this._APICallerManager();
            this._APICallerManager_not_logged();
        },

        // LOGGED
        _APICallerManager(){
            if(this.tab_indexId=='media'){

                this._getMediaVideos();

            } else if(this.tab_indexId=='masterclass'){
                
                this._getMasterClassVideos();

            } else if(this.tab_indexId=='48segundos'){
                
                this._get48secsVideos();

            } else if(this.tab_indexId=='bunkerinversion'){
                
                this._getInvestmentBunkerVideos();

            }
        },

        // NOT LOGGED
        _APICallerManager_not_logged(){
            if(this.tab_indexId=='media'){

                this._getMediaVideos();

            } else if(this.tab_indexId=='masterclass'){

                this._getMasterClassVideos();
        
            } else if(this.tab_indexId=='48segundos'){

                this._get48secsVideos();
                
            } else if(this.tab_indexId=='bunkerinversion'){

                this._getInvestmentBunkerVideos();
                
            }
        },

        _getMediaVideos(){
            axios.get(process.env.VUE_APP_API +  '/api/v1/videos/media-videos', { headers: authHeader() })
            .then(
                response => {
                    this.media_nodes_vimeo = response.data[0];
                    this.media_nodes_youtube = response.data[1];

                    this.media_nodes_vimeo.forEach(element => {
                        var urls_vimeo = element.field_content_video_vimeo.und[0].value
                        this.media_urls_vimeo.push(urls_vimeo)
                    })

                    this.media_nodes_youtube.forEach(element => {
                        var urls_youtube = element.field_content_video_youtube.und[0].value
                        this.media_urls_youtube.push(urls_youtube)
                    })
                },
                error => {
                    this.$vueOnToast.pop('error', 'Error al recuperar los videos de los medios');
                }
            )
        },

        _getMediaVideosMore(){
            this.media_btn_counter++;

            if(this.media_btn_counter == 1){
                axios.get(process.env.VUE_APP_API +  '/api/v1/videos/media-videos-more', { headers: authHeader() })
                .then(
                    response => {
                        this.media_nodes_vimeo_more = response.data[0];
                        this.media_nodes_youtube_more = response.data[1];
    
                        this.media_nodes_vimeo_more.forEach(element => {
                            var urls_vimeo = element.field_content_video_vimeo.und[0].value
                            this.media_urls_vimeo_more.push(urls_vimeo)
                        })
    
                        this.media_nodes_youtube_more.forEach(element => {
                            var urls_youtube = element.field_content_video_youtube.und[0].value
                            this.media_urls_youtube_more.push(urls_youtube)
                        })
                    },
                    error => {
                        this.$vueOnToast.pop('error', 'Error al recuperar los videos de los medios');
                    }
                )
            }else if(this.media_btn_counter == 2){
                axios.get(process.env.VUE_APP_API +  '/api/v1/videos/media-videos-more-more', { headers: authHeader() })
                .then(
                    response => {
                        this.media_nodes_vimeo_more_more = response.data[0];
                        this.media_nodes_youtube_more_more = response.data[1];
    
                        this.media_nodes_vimeo_more_more.forEach(element => {
                            var urls_vimeo = element.field_content_video_vimeo.und[0].value
                            this.media_urls_vimeo_more_more.push(urls_vimeo)
                        })
    
                        this.media_nodes_youtube_more_more.forEach(element => {
                            var urls_youtube = element.field_content_video_youtube.und[0].value
                            this.media_urls_youtube_more_more.push(urls_youtube)
                        })
                    
                    },
                    error => {
                        this.$vueOnToast.pop('error', 'Error al recuperar los videos de los medios');
                    }
                )
            }
        },

        _get48secsVideos(){
            axios.get(process.env.VUE_APP_API + '/api/v1/videos/48secs-videos', { headers: authHeader() })
            .then(
                response => {
                    this.x48secs_nodes = response.data;
                    this.x48secs_nodes.forEach(element => {
                        var url = element.field_content_video_youtube.und[0].value
                        let first = url.substring(0,13)
                        let embed = "be.com/embed/"
                        let end = url.substring(17)
                        url = first + embed + end
                        this.x48secs_urls.push(url)
                    })
                    
                },
                error => {
                    this.$vueOnToast.pop('error', 'Error al recuperar los videos de los 48 segundos');
                }
            )
        },

        _get48secsVideosMore(){
            this.seconds_btn_counter++;

            if(this.seconds_btn_counter == 1){

                axios.get(process.env.VUE_APP_API + '/api/v1/videos/48secs-videos-more', { headers: authHeader() })
                .then(
                    response => {
                        this.x48secs_nodes_more = response.data;
                        this.x48secs_nodes_more.forEach(element => {
                            var url = element.field_content_video_youtube.und[0].value
                            let first = url.substring(0,13)
                            let embed = "be.com/embed/"
                            let end = url.substring(17)
                            url = first + embed + end
                            this.x48secs_urls_more.push(url)
                        })
                        
                    },
                    error => {
                        this.$vueOnToast.pop('error', 'Error al recuperar los videos de los 48 segundos');
                    }
                )

            } else if(this.seconds_btn_counter == 2){

                axios.get(process.env.VUE_APP_API + '/api/v1/videos/48secs-videos-more-more', { headers: authHeader() })
                .then(
                    response => {
                        this.x48secs_nodes_more_more = response.data;
                        this.x48secs_nodes_more_more.forEach(element => {
                            var url = element.field_content_video_youtube.und[0].value
                            let first = url.substring(0,13)
                            let embed = "be.com/embed/"
                            let end = url.substring(17)
                            url = first + embed + end
                            this.x48secs_urls_more_more.push(url)
                        })
                        
                    },
                    error => {
                        this.$vueOnToast.pop('error', 'Error al recuperar los videos de los 48 segundos');
                    }
                )

            } else if(this.seconds_btn_counter == 3){

                axios.get(process.env.VUE_APP_API + '/api/v1/videos/48secs-videos-more-more-more', { headers: authHeader() })
                .then(
                    response => {
                        this.x48secs_nodes_more_more_more = response.data;
                        this.x48secs_nodes_more_more_more.forEach(element => {
                            var url = element.field_content_video_youtube.und[0].value
                            let first = url.substring(0,13)
                            let embed = "be.com/embed/"
                            let end = url.substring(17)
                            url = first + embed + end
                            this.x48secs_urls_more_more_more.push(url)
                        })
                        
                    },
                    error => {
                        this.$vueOnToast.pop('error', 'Error al recuperar los videos de los 48 segundos');
                    }
                )

            } else if(this.seconds_btn_counter == 4){

                axios.get(process.env.VUE_APP_API + '/api/v1/videos/48secs-videos-more-more-more-more', { headers: authHeader() })
                .then(
                    response => {
                        this.x48secs_nodes_more_more_more_more = response.data;
                        this.x48secs_nodes_more_more_more_more.forEach(element => {
                            var url = element.field_content_video_youtube.und[0].value
                            let first = url.substring(0,13)
                            let embed = "be.com/embed/"
                            let end = url.substring(17)
                            url = first + embed + end
                            this.x48secs_urls_more_more_more_more.push(url)
                        })
                        
                    },
                    error => {
                        this.$vueOnToast.pop('error', 'Error al recuperar los videos de los 48 segundos');
                    }
                )

            }
        },

        _getMasterClassVideos(){
            axios.get(process.env.VUE_APP_API + '/api/v1/videos/masterclass-videos', { headers: authHeader() })
            .then(
                response => {
                    this.masterclass_nodes = response.data;
                    this.masterclass_nodes.forEach(e => {
                        var url = e.field_video_url.und[0].value;
                        this.masterclass_urls.push(url);
                    });  
                },
                error => {
                    this.$vueOnToast.pop('error', 'Error al recuperar los videos de las masterclasses');
                }
            )
        },

        _getInvestmentBunkerVideos(){
            axios.get(process.env.VUE_APP_API + '/api/v1/videos/investment-bunker-videos', { headers: authHeader() })
            .then(
                response => {
                    this.investment_bunker_nodes = response.data;
                    this.investment_bunker_nodes.forEach(element => {
                        var url = element.field_bunker_video_youtube.und[0].value
                        let first = url.substring(0,13)
                        let embed = "be.com/embed/"
                        let end = url.substring(17)
                        url = first + embed + end
                        this.investment_urls.push(url);
                    });
                },
                error => {
                    this.$vueOnToast.pop('error', 'Error al recuperar los videos del bunker de la inversión');
                }
            )
        },

        _infoMessage(){
            this.$vueOnToast.pop('info', 'Regístrese para ver el contenido.');
        },

        /*_scrollToTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }*/
    },
}
</script>

<style>

    .mdc-tab-scroller {
        margin: 0 !important;
    }

</style>

<style scoped>
    html{
        overflow-y: scroll;
    }

    .title-container{
        margin-left: 50px;
    }

    .main-videos-container{
        margin-left: 10px;
    }

    .title-style {
        margin-top: 0;
    }

    .media-video-content-container,
    .x48sec-video-content-container,
    .investment-bunker-video-content-container,
    .masterclass-videos-content-container{
        display: inline-block;
        padding: 2px;
    }

    .title-style-container{
        background-color: #0f0f1a;
        width: 423px;
        color: white;
        margin-bottom: 3px;
        text-align: center;
        font-weight: bold;
        height: 25px;
        padding: 6px;
        font-size: 15px;
    }

    .forbidden-content-container{
        filter: blur(7px);
        -webkit-filter: blur(7px);
    }

    .forbidden-content-container iframe{
        overflow: hidden;
        pointer-events: none;
    }

    .lock-icon-style{
        position:absolute;
        margin-left: 200px;
        z-index:1;
        margin-top: 100px;
        color: white;
        font-size: 50px;
        cursor: pointer;
    }
    .lock-icon-style:hover{
        margin-top: 101px;
    }

    .lock-icon-style:active{
        margin-top: 102px;
    }

    @media screen and (max-width: 850px){

        .title-container{
            text-align: center;
        }

        .main-videos-container{
            margin: 0;
        }

        iframe{
            width: 390px;
        }

        .title-style-container{
            width: 382px;
        }

        .media-video-content-container,
        .x48sec-video-content-container,
        .investment-bunker-video-content-container,
        .masterclass-videos-content-container{
            padding: 0px !important;
        }

        .image-style{
            cursor:pointer; 
            padding: 0px !important;
            width: 390px;
        }

        .mdc-tab-scroller__scroll-content {
            flex-direction: column !important;
        }

        .mdc-tab-bar.directionality-toggle {
            display: flex;
            justify-content: center;
            justify-items: center;
            align-items: center;
            align-content: center;
        }

        .mdc-tab-scroller {
            width: 75%;
        }

        .title-container {
            margin-left: 0;
        }
    }

    @media screen and (min-width: 900px){
        .image-style{
            cursor:pointer; 
            padding:2px;
            height: 245px;
            width: 435px;
        }
    }

    .read-more-media-btn-container,
    .read-more-48segundos-btn-container,
    .read-more-bunkerinversion-btn-container,
    .read-more-masterclass-btn-container{
        text-align: center;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .read-more-media-btn-style,
    .read-more-48segundos-btn-style,
    .read-more-bunkerinversion-btn-style,
    .read-more-masterclass-btn-style
    {
        color: white;
        background-color: #0f0f1a;
        width: 200px;
        border-radius: 25px;
        height: 50px;
        border: none;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }

    .read-more-media-btn-style:hover,
    .read-more-48segundos-btn-style:hover,
    .read-more-bunkerinversion-btn-style:hover,
    .read-more-masterclass-btn-style:hover{
        background-color: #0f0f1aeb;
    }

    .accordion-video-content {
        text-align: center;
    }

    .tab-videos{
        width: 75%;
    }
    .div-tab-videos{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .mdc-tab-scroller{
        margin: 0 !important;
    }
</style>