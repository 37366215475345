import { render, staticRenderFns } from "./Videos.vue?vue&type=template&id=010e72b7&scoped=true&"
import script from "./Videos.vue?vue&type=script&lang=js&"
export * from "./Videos.vue?vue&type=script&lang=js&"
import style0 from "./Videos.vue?vue&type=style&index=0&id=010e72b7&prod&lang=css&"
import style1 from "./Videos.vue?vue&type=style&index=1&id=010e72b7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010e72b7",
  null
  
)

export default component.exports